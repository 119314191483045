import { IPrebindResolver, PrebindResolver, IPrebindHandler } from "../resolvers/prebinding/PrebindResolver";

export class Prebinding {
    private static processor: IPrebindResolver = new PrebindResolver();

    static registerPrebindHelper(helperName: string, handler: IPrebindHandler): void {
        this.processor.registerPrebindHelper(helperName, handler);
    }

    static getHelper(helperName: string): IPrebindHandler {
        return this.processor.getHelper(helperName);
    }

    static applyPrebindingOnElement(rootElement: HTMLElement): void {
        if (this.processor.hasNotAppliedPrebind(rootElement)) {
            this.processor.applyToElement(rootElement);
        }
    }

    static applyPrebindingOnChildren(rootElement: HTMLElement): void {
        this.nodeListToArray(rootElement.querySelectorAll("*"))
            .map(element => element as HTMLElement)
            .filter(element => this.processor.hasNotAppliedPrebind(element))
            .forEach(element => this.processor.applyToElement(element));
    }

    private static nodeListToArray<TElementType extends Node>(elements: NodeListOf<TElementType>): TElementType[] {
        const array: TElementType[] = [];
        for (var i = 0, length = elements.length; i < length; i++) {
            array.push(elements[i]);
        }
        return array;
    }
}

import { IPrebindHelper, IPrebindHelperInitializationArgs } from "../PrebindHelper";
import { IPrebindHandler } from "../PrebindResolver";

export class CoveoForSitecoreAnalyticsEndpointPrebind implements IPrebindHelper {
    public name: string = "coveoForSitecoreAnalyticsEndpoint";

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (currentValue: string, element: HTMLElement) => {
            return currentValue || `${args.context.scRestEndpointUri}/coveoanalytics`;
        };
    }
}

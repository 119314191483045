import { IFieldTypeResolver } from "./IFieldTypeResolver";
import { LegacyFieldTypeResolver } from "./LegacyFieldTypeResolver";

export interface ICoveoFieldHandler {
    isCoveoFieldName(fieldName: string): boolean;
    toCoveo(fieldName: string): string;
    addCoveoFieldDelimiter(fieldName: string): string;
}

export interface IExternalFieldConfig {
    fieldName: string;
    shouldEscape: boolean;
}

export class CoveoFieldsHandler implements ICoveoFieldHandler {
    // 'z' and 'x' are also considered as special characters because
    // they are used in the escaping logic. So we have to remove them
    // from the regular expression pattern.
    private static readonly nonSpecialCharactersString = "[a-wyA-WY0-9]";
    private static readonly nonSpecialCharacters = new RegExp(CoveoFieldsHandler.nonSpecialCharactersString);
    private static readonly coveoFieldDelimiter = "@";

    private readonly resolver: IFieldTypeResolver;

    constructor(resolver: IFieldTypeResolver, prefix: string, suffix: string);
    /**
     * @deprecated. Use the `constructor(IFieldTypeResolver, string, string)` constructor instead.
     */
    constructor(resolver: IExternalFieldConfig[], prefix: string, suffix: string);
    constructor(resolver: IExternalFieldConfig[] | IFieldTypeResolver, private prefix: string, private suffix: string) {
        if ((<IFieldTypeResolver>resolver).shouldDecorate !== undefined) {
            this.resolver = resolver as IFieldTypeResolver;
        } else {
            // Handle legacy constructor case.
            this.resolver = new LegacyFieldTypeResolver(resolver as IExternalFieldConfig[]);
        }
    }

    public toCoveo(fieldName: string): string {
        if (this.isValidFieldName(fieldName)) {
            const lowerCaseFieldName = fieldName.toLowerCase();
            const fieldWithoutDelimiter = this.stripCoveoFieldDelimiter(lowerCaseFieldName);
            const translatedFieldWithoutDelimiter = this.translateFieldName(fieldWithoutDelimiter);
            return this.startsWithAt(fieldName) ? this.addCoveoFieldDelimiter(translatedFieldWithoutDelimiter) : translatedFieldWithoutDelimiter;
        } else {
            console.error(`Could not translate the '${fieldName}' field to a Coveo field. Returning as is.`);
            return fieldName;
        }
    }

    public addCoveoFieldDelimiter(fieldName: string): string {
        let fieldNameWithDelimiter = fieldName;
        if (!this.startsWithAt(fieldName)) {
            fieldNameWithDelimiter = `${CoveoFieldsHandler.coveoFieldDelimiter}${fieldName}`.toLowerCase();
        }
        return fieldNameWithDelimiter;
    }

    public isCoveoFieldName(fieldName: string): boolean {
        const strippedFieldName = this.stripCoveoFieldDelimiter(fieldName);
        return this.resolver.isExternalField(strippedFieldName) || this.isDecorated(strippedFieldName);
    }

    private isDecorated(fieldName: string): boolean {
        const regexString = this.prefix + "[\\w]+" + this.suffix;
        const coveoFieldNameRegex = new RegExp(regexString);
        return coveoFieldNameRegex.test(fieldName);
    }

    private isValidFieldName(fieldName: string): boolean {
        return typeof (fieldName) !== "undefined" && fieldName !== "";
    }

    private startsWithAt(fieldName: string): boolean {
        return fieldName[0] === CoveoFieldsHandler.coveoFieldDelimiter;
    }

    private stripCoveoFieldDelimiter(fieldName: string): string {
        let field = fieldName;
        if (this.startsWithAt(fieldName)) {
            field = field.substr(1);
        }
        return field;
    }

    private translateFieldName(fieldName: string): string {
        let field = fieldName;

        if (this.resolver.shouldEscapeSpecialCharacters(fieldName)) {
            field = this.replaceSpecialCharacters(field);
        }
        if (this.resolver.shouldDecorate(fieldName)) {
            field = this.prefix + field + this.suffix;
        }
        if (this.resolver.shouldEscapeFirstCharacter(field)) {
            field = this.replaceFirstCharacter(field);
        }

        return field;
    }

    private replaceFirstCharacter(fieldName: string): string {
        const characters = fieldName.split("");
        const firstCharacter = characters.shift();
        characters.unshift(this.escapeSpecialCharacter(firstCharacter));
        return characters.join("");
    }

    private replaceSpecialCharacters(fieldName: string): string {
        return fieldName.split("")
            .map(this.replaceSpecialCharacter.bind(this))
            .join("");
    }

    private replaceSpecialCharacter(character: string): string {
        if (character.match(CoveoFieldsHandler.nonSpecialCharacters)) {
            return character;
        } else {
            return this.escapeSpecialCharacter(character);
        }
    }

    private escapeSpecialCharacter(character: string): string {
        return `z${character.charCodeAt(0)}x`;
    }
}

import { SearchInterfacesProvider } from "../../utils/SearchInterfaceProvider";
import { ElementWithContextInitializer } from "./ElementWithContextInitializer";

import {
    $$,
    Dom,
    get as getCoveoComponents,
    initSearchbox,
    InitializationEvents
} from "coveo-search-ui";

export class SearchboxInitializer {
    private searchInterfacesProvider = new SearchInterfacesProvider();

    initSearchboxIfStandalone(searchboxElement: HTMLElement, searchPageUri?: string, options?: any): Promise<void> {
        const searchInterfaces = this.searchInterfacesProvider.getSearchInterfacesInPage(["Recommendation", "SearchInterface"]);
        return this.waitForSearchInterfacesInitialization(searchInterfaces).then(() => {
            if (this.searchboxElementIsNotInitialized(searchboxElement)) {
                if (!!searchPageUri) {
                    ElementWithContextInitializer.initializeContextWithinElement(searchboxElement.parentElement);
                    // coveo-search-ui doesn't like an initSearchbox directly on the searchbox.
                    initSearchbox(searchboxElement.parentElement, searchPageUri, options);
                } else {
                    console.warn(`The search box is not included in any search interface and the "searchPageUri" parameters is not specified. Consider using a Global Searchbox instead.`, searchboxElement);
                }
            }
        });
    }

    private searchboxElementIsNotInitialized(searchBoxElement: HTMLElement): boolean {
        return typeof (getCoveoComponents(searchBoxElement, Coveo.Searchbox)) === "undefined";
    }

    private waitForSearchInterfacesInitialization(searchInterfaces: HTMLElement[]): Promise<void> {
        return Promise.all(this.getAllSearchInterfacesAfterComponentInitialization(searchInterfaces))
            .then(() => { });
    }

    private getAllSearchInterfacesAfterComponentInitialization(searchInterfaces: HTMLElement[]): Promise<void>[] {
        return searchInterfaces.map(searchInterface => $$(searchInterface))
            .map(searchInterface => this.waitForSearchInterfaceInitialization(searchInterface));
    }

    private waitForSearchInterfaceInitialization(searchInterface: Dom): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            searchInterface.on(InitializationEvents.afterComponentsInitialization, () => resolve());
        });
    }
}

import {
    ExpressionBuilder
} from "coveo-search-ui";

export interface IBuildingExternalContentEventArgs {
    sources: string[];
    collections: string[];
}

export interface IBuildingLocalSitecoreInstanceExpressionEventArgs {
    expression: ExpressionBuilder;
}

export class SitecoreExpressionEvents {
    public static onBuildingSitecoreExternalContent = "onBuildingSitecoreExternalContent";
    public static onBuildingLocalSitecoreInstanceExpression = "onBuildingLocalSitecoreInstanceExpression";
}

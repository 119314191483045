import { IPrebindHelper, IPrebindHelperInitializationArgs } from "../PrebindHelper";
import { IPrebindHandler } from "../PrebindResolver";

import { SearchInterface } from "coveo-search-ui";

interface ISearchInterfaceValueResolver {
    canResolve(mainSearchInterfaceId: string, element: HTMLElement): boolean;
    getValue(mainSearchInterfaceId: string, element: HTMLElement): string;
}

export class MainSearchInterfaceSelectorPrebind implements IPrebindHelper {
    public name: string = "mainSearchInterfaceSelector";

    private resolvers: ISearchInterfaceValueResolver[] = [
        new ResolveIfSelector(),
        new ResolveIfSearchInterfaceIdInPage(),
        new ResolveParentSearchInterfaceId(),
        new UseMainSearchInterfaceSelectorAsIs()
    ];

    public getPrebind(args: IPrebindHelperInitializationArgs): IPrebindHandler {
        return (mainSearchInterfaceId: string, element: HTMLElement) => {
            const resolver: ISearchInterfaceValueResolver = this.getFirstValidResolver(mainSearchInterfaceId, element);

            return resolver.getValue(mainSearchInterfaceId, element);
        };
    }

    private getFirstValidResolver(mainSearchInterfaceId: string, element: HTMLElement): ISearchInterfaceValueResolver {
        let resolver: ISearchInterfaceValueResolver;

        do {
            if (this.resolvers.length === 0) {
                const message = "Could not resolve the search interface element using the current resolver. Ensure that a valid value is set.";
                console.error(message, {
                    mainSearchInterfaceId: mainSearchInterfaceId,
                    element: element
                });
                throw message;
            }
            resolver = this.resolvers.shift();
        } while (!resolver.canResolve(mainSearchInterfaceId, element));

        return resolver;
    }
}

class ResolveIfSearchInterfaceIdInPage implements ISearchInterfaceValueResolver {
    canResolve(mainSearchInterfaceId: string): boolean {
        return this.isMainSearchInterfaceDefined(mainSearchInterfaceId) &&
            this.isIdSelectorValid(`#${mainSearchInterfaceId}`);
    }

    getValue(mainSearchInterfaceId: string): string {
        return `#${mainSearchInterfaceId}`;
    }

    private isMainSearchInterfaceDefined(mainSearchInterfaceId: string): boolean {
        return !!mainSearchInterfaceId;
    }

    private isIdSelectorValid(selector: string): boolean {
        return document.querySelector(selector) !== null;
    }
}

class ResolveParentSearchInterfaceId implements ISearchInterfaceValueResolver {
    private foundId: string;

    canResolve(mainSearchInterfaceId: string, element: HTMLElement): boolean {
        return this.getValue(mainSearchInterfaceId, element) !== null;
    }

    getValue(mainSearchInterfaceId: string, element: HTMLElement): string {
        if (typeof (this.foundId) === "undefined") {
            this.foundId = this.tryFindParentSearchInterfaceFromElement(element);
        }

        return this.foundId;
    }

    private tryFindParentSearchInterfaceFromElement(element: HTMLElement): string {
        const parentSearchInterface = this.findParentWithClass(element, `Coveo${SearchInterface.ID}`);

        return !!parentSearchInterface ? `#${parentSearchInterface.id}` : null;
    }

    private findParentWithClass(element: HTMLElement, className: string): HTMLElement {
        let parent = element.parentElement;
        while ((parent = parent.parentElement) && !parent.classList.contains(className)) { }
        return parent;
    }
}

class ResolveIfSelector implements ISearchInterfaceValueResolver {
    canResolve(mainSearchInterfaceId: string, element: HTMLElement): boolean {
        return !!mainSearchInterfaceId &&
            this.isStartingWithSelectorCharacter(mainSearchInterfaceId);
    }

    getValue(mainSearchInterfaceId: string, element: HTMLElement): string {
        return mainSearchInterfaceId;
    }

    private isStartingWithSelectorCharacter(mainSearchInterfaceId: string): boolean {
        const firstCharacter = mainSearchInterfaceId[0];

        return [".", "#"].indexOf(firstCharacter) !== -1;
    }
}


class UseMainSearchInterfaceSelectorAsIs implements ISearchInterfaceValueResolver {
    canResolve(mainSearchInterfaceId: string, element: HTMLElement): boolean {
        return true;
    }

    getValue(mainSearchInterfaceId: string, element: HTMLElement): string {
        return mainSearchInterfaceId;
    }
}

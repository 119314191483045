export interface ISitecoreContextInitializationEventArgs {
    sitecoreItemId: string;
    sitecoreItemUri: string;
    sitecorePageName: string;
    shouldHaveAnalyticsComponent: boolean;
    analyticsEnabled: boolean;
    indexSourceName: string;
    currentLanguage: string;
    languageFieldName: string;
    latestVersionFieldName: string;
    isEditing: boolean;
    sitecorePageFullPath: string;
    siteName: string;
}

export class SitecoreContextEvents {
    public static onSitecoreContextInitialization = "onSitecoreContextInitialization";
}

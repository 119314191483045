import { SearchEndpoint as CoveoSearchEndpoint } from "coveo-search-ui";

export interface ICoveoForSitecoreEndpointOptions {
    restEndpointUri?: string;
    itemUri: string;
    siteName: string;
}

export class SearchEndpoint {
    static configureSitecoreEndpoint(options: ICoveoForSitecoreEndpointOptions): void {
        if (typeof (options) === "undefined") {
            throw Error("You must define endpoint options when calling 'configureSitecoreEndpoint'.");
        }
        CoveoSearchEndpoint.endpoints["default"] = SearchEndpoint.createNewEndpoint(options);
    }

    private static createNewEndpoint(options: ICoveoForSitecoreEndpointOptions): CoveoSearchEndpoint {
        return new CoveoSearchEndpoint({
            restUri: options.restEndpointUri || "/coveo/rest",
            queryStringArguments: {
                "sitecoreItemUri": options.itemUri,
                "siteName": options.siteName
            }
        });
    }
}

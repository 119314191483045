import { ISearchInterfaceComponentInitializer, getInitializerInstance } from "../../resolvers/components/SearchInterfaceComponentInitializer";
import { ISitecoreExperienceEditor, getExperienceEditorInstance } from "./SitecoreExperienceEditor";

import {
    SearchInterface,
    get as getCoveoComponent
} from "coveo-search-ui";

export class ExternalComponentInitializer {
    constructor(private experienceEditor: ISitecoreExperienceEditor = getExperienceEditorInstance(), private searchInterfaceComponentInitializer: ISearchInterfaceComponentInitializer = getInitializerInstance()) {
    }

    public initializeExternalComponentSection(externalComponentsSection: HTMLElement): void {
        const searchInterface = this.findSearchInterfaceForSection(externalComponentsSection);
        if (!!searchInterface) {
            this.searchInterfaceComponentInitializer.loadComponentsWithSearchInterfaceInitialization(searchInterface, externalComponentsSection);
        }
    }

    public initializeExperienceEditorHooksForExternalComponentsSection(externalComponentsSection: HTMLElement): void {
        const searchInterface = this.findSearchInterfaceForSection(externalComponentsSection);
        if (!!searchInterface) {
            this.experienceEditor.registerOnLoadRenderingFromUrl(callbackData => {
                const component = this.experienceEditor.getComponentFromLoadRenderingFromUrlCallbackData(callbackData);

                if (externalComponentsSection.contains(component)) {
                    this.searchInterfaceComponentInitializer.loadComponentsForSearchInterface(getCoveoComponent(searchInterface, SearchInterface) as SearchInterface, component);
                }
            });
        }
    }

    private findSearchInterfaceForSection(externalComponentsSection: HTMLElement): HTMLElement {
        const searchInterfaceId = externalComponentsSection.dataset.searchInterfaceId;
        const searchInterfaceElement = document.getElementById(searchInterfaceId);
        if (!!searchInterfaceElement) {
            return searchInterfaceElement;
        } else {
            console.warn(`Could not find the search interface element with the following ID: ${searchInterfaceId}. Validate "data-search-interface-id" on the external components section element.`, externalComponentsSection);
        }
    }
}
